.button-primary {
  background-color: white;
  border: 1px solid darken($color: #0000ff, $amount: 0.5);
  border-radius: 5px;
  color: darken($color: #0000ff, $amount: 0.5);
  letter-spacing: 0.1rem;
  padding: 0.75rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: darken($color: #0000ff, $amount: 0.5);
    color: white;
  }
}
