*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  /* This is the root font size for use with the "rem" unit */

  @media (max-width: 3100px) {
    font-size: 75%;
  }

  @media (max-width: 1920px) {
    font-size: 62.5%; // 1rem = 10px
  }

  @media (max-width: 1280px) {
    font-size: 50%; // 1rem = 8px
  }
}

body {
  background-color: lightgrey;
  box-sizing: border-box;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100vw;

  @media (max-width: 1280px) {
    height: unset;
  }
}

a {
  text-decoration: none;

  &:hover {
    color: white;
  }
}

.container {
  background-color: white;
}

.list {
  list-style-type: none;
}

.hidden {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.cursor-none {
  cursor: none;
  pointer-events: none;
}

.full-view {
  height: 100vh;
  width: 100%;
}

.orangered {
  background-color: orangered;
}

.grey-back {
  background-color: $dimgrey;
}

.qs-head {
  max-height: 100px;
}
