.popup {
  background-color: black;
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: flex-start;
  z-index: 2000;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &-body {
    height: 80vh;
    width: 35vw;
    background-color: white;
    padding: 0.75rem;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 1200px) {
      width: 50vw;
    }

    @media (max-width: 900px) {
      width: 70vw;
    }

    @media (max-width: 600px) {
      width: 90vw;
    }

    &-black-box {
      background-color: black;
      outline: 1px solid #ffdb4c;
    }

    &-wider {
      @media (min-width: 1200px) {
        width: 50vw;
      }
    }

    &-big-slideshow-container {
      display: flex;
      justify-content: center;
      align-self: center;
    }

    &-big-slideshow {
      height: 600px;
      width: 800px;
      background-color: white;
    }
  }
}

.scroll-me {
  height: 100%;
  width: 100%;
  padding-bottom: 1rem;
  text-align: center;

  * {
    width: 100%;
    margin-bottom: 0.5rem;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.custom-flex-column {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  * {
    width: 100%;

    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
}

.monroney-viewer {
  width: 100%;
  height: auto;
}

.loading-comp {
  position: relative;
  height: 100%;
  width: 100%;
}

.big-pic {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;

  &-faded {
    opacity: 50%;
  }

  &-loading-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.big-slideshow-arrow {
  font-family: Belleza;
  font-size: 3rem;
  border: 2px solid white;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  padding: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: grey;
  }
}
